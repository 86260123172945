import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import Seo from '../components/seo'
import Layout from '../components/layout'

const About = ({ data }) => {
  return (
    <Layout>
      <Seo title="Hakkımızda" />

      <div className="container">
        <div className="about-hero">
          <StaticImage
            src="../images/logo.svg"
            alt="BM Creative Works"
            className="menu-logo"
          />
          <p>
            <span>
              Değişen müşteriler, rakipler ve teknoloji çağında şirketlerin
              rekabet edebilmeleri için geleceğe uyum sağlamalarına yardımcı
              olan dijital deneyimler yaratıyoruz.
            </span>
            Stratejik değer yaratan, cezbedebilen ve etkileşim kurabilen çağdaş
            estetikli yaratıcı projelerle müşterilerimizin hikayelerini
            anlatmalarına, büyümelerine ve her zamankinden daha rekabetçi bir
            dijital dünyada öne çıkmalarına yardımcı oluyoruz.
          </p>
        </div>
      </div>

      <div className="about-image">
        <StaticImage src="../images/about-bg.png" alt="DO SOMETHING GREAT" />
      </div>
      <div className="container">
        <div className="about-textarea">
          <div className="area1">Tasarımın dönüştürücü gücüne inanıyoruz.</div>
          <div className="area2">
            Bir ürün veya hizmetin niteliklerinin tüketicinin kalbine ve zihnine
            sızmasının bir yolunu ararız. Her zaman günceliz ve yeni
            olasılıkları deneyimliyoruz.
          </div>
          <div className="area3">
            Müşterilerimizin değerlerini ve ihtiyaçlarını analiz ediyoruz.
          </div>
          <div className="area4">
            Bir müşteri bir projeyle stüdyo kapısından girdiğinde, onunla oturur
            ve özel iletişim stratejisini tasarlarız. Tüm iletişim
            ihtiyaçlarınıza cevap verecek kriterler, zamanlar ve aksiyonlar
            oluşturuyoruz.
          </div>
        </div>
      </div>

      <div className="team">
        <div className="container">
          <h1>Ekip</h1>
        </div>
        <div className="members">
          <div className="member1">
            <StaticImage
              src="../images/1.png"
              alt="Bartu Tabakcı"
              className="picture"
              quality="100"
            />
            <StaticImage
              src="../images/cofounder.png"
              alt="Co Founder"
              className="title"
            />
            <h3>
              BARTU <br /> TABAKCI
            </h3>
          </div>
          <div className="member1">
            <StaticImage
              src="../images/2.png"
              alt="Ali Mert Şentürklü"
              className="picture"
            />
            <StaticImage
              src="../images/cofounder.png"
              alt="Co Founder"
              className="title"
            />
            <h3>
              ALİ MERT <br /> ŞENTÜRKLÜ
            </h3>
          </div>
          <div className="member1">
            <StaticImage
              src="../images/3.png"
              alt="Ali Tolga Öztürk"
              className="picture"
            />
            <StaticImage
              src="../images/brandmanager.png"
              alt="Brand Manager"
              className="title"
            />
            <h3>
              ALİ TOLGA <br /> ÖZTÜRK
            </h3>
          </div>
          <div className="member1">
            <StaticImage
              src="../images/4.png"
              alt="Orhan Bayram"
              className="picture"
            />
            <StaticImage
              src="../images/brandmanager.png"
              alt="Brand Manager"
              className="title"
            />
            <h3>
              ORHAN <br /> BAYRAM
            </h3>
          </div>
          <div className="member1">
            <StaticImage
              src="../images/5.png"
              alt="Neslihan Karagöz"
              className="picture"
            />
            <StaticImage
              src="../images/artdirector.png"
              alt="Art Director"
              className="title"
            />
            <h3>
              NESLİHAN <br /> KARAGÖZ
            </h3>
          </div>
          <div className="member1">
            <StaticImage
              src="../images/6.png"
              alt="Batu Aksoy"
              className="picture"
            />
            <StaticImage
              src="../images/developer.png"
              alt="Developer"
              className="title"
            />
            <h3>
              BATU <br /> AKSOY
            </h3>
          </div>
          <div className="member1">
            <StaticImage
              src="../images/7.png"
              alt="Gökberk Türker"
              className="picture"
            />
            <StaticImage
              src="../images/designer.png"
              alt="Graphic Designer"
              className="title"
            />
            <h3>
              GÖKBERK <br /> TÜRKER
            </h3>
          </div>
        </div>
      </div>

      <div className="clients">
        <div className="container">
          <h1>Kimlerle Çalıştık?</h1>
        </div>
        <div className="client-list">
          {data.allFile.edges.map(({ node }) => {
            return (
              <GatsbyImage
                key={node.id}
                image={node.childImageSharp.gatsbyImageData}
              />
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Clients {
    allFile(filter: { absolutePath: { regex: "//clients/" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              placeholder: BLURRED
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`

export default About
